body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}
