@import '_colors';

.app {
  display: flex;
  background-color: $main-dark;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  color: $main-text-light;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0 auto;
    min-height: 1200px;
  }
}

h1 {
  font-size: 4rem;

  @media(max-width: 768px) {
    font-size: 3ch;
  }
}

h2 {
  font-size: 3rem;

  @media(max-width: 768px) {
    font-size: 2.5rem;
  }
}

a {
  color: $main-light;
}

.top-nav {
  // Override material ui
  background-color: $main-dark  !important;
  box-shadow: unset !important;

  button {
    transition: none !important;
    text-transform: unset !important;
    font-family: unset;
    font-size: 1.5rem;
    font-weight: 200;

    @media (max-width: 768px) {
      font-size: 0.95rem;
    }
  }

  a {
    position: relative;
    text-decoration: none;
    color: $main-highlight;

    &.active {
      text-decoration: underline;
      transition: all 0.5s ease-in-out;
    }

    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 10px;
      bottom: 0;
      transition: width 0.5s ease-in-out !important;
    }
  }


  @media(max-width: 768px) {
    margin-left: 0px;
    background-color:$secondary-dark !important;
    height: 65px;

    .MuiBox-root {
      display: flex !important;
    }
  }
}

.descriptive-text {
  line-height: 1.5;
}

.home {
  padding-top: 100px;
  min-height: 400px;

  @media(max-width: 768px){
    padding-top: unset;
  }

  .descriptive-text {
    line-height: 2;
  }
}

.main-section {
  height: 500px;
  display: flex;
  padding: 50px 225px 0px 225px;
  align-items: center;
  border-radius: 10px;
  justify-content: center;

  @media(max-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    gap: 20px;
    padding: 0;
  }
}

.main-personal-photo {
  width: auto;
  height: 550px;
  min-height: 550px;
  width: auto;
  border-radius: 50%;
  padding: 30px;
  margin-top: 100px;

  @media(max-width: 768px) {
    margin-top: 0;
    padding-top: 0;
    height: 300px;
    min-height: 300px;
  }
}

.about-content {
  flex-direction: column;
  font-size: 1.18rem;
}

.app:has(.about-content) {
    background-image: url('about_me.png');
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: 800px auto;
    background-attachment: fixed; 

    @media(max-width: 768px) {
      background-image: none;
    }
}

.contact-me-photo {
  @media(max-width: 768px) {
   margin-top: -100px;
  }
}
.contact-section {
  min-width: 400px;

  > h2 {
    text-align: center;
  }

  .schedule-link {
    display: flex;
    padding: 10px;
    font-size: 1.12rem;

    > svg {
      height: 25px;
      width: 25px;
      margin-right: 15px;
      color: $main-highlight;
    }

    a {
      align-self: center;
    }
  }
}

.project-section {
  margin-top: 180px;
  min-height: 800px;
  display: flex;
  flex-direction: column;

  .MuiCircularProgress-root {
    margin: auto !important;
  }

  @media(max-width: 768px) {
    margin-top: 0;
    flex-direction: unset;
    flex-wrap: wrap;
    width: 100%;

    .MuiGrid-root {
      padding-top: 10px;
    }
  }
}

.card-link {
  div {
    min-height: 230px;
    background-color: $secondary-dark;
    border: 2px solid transparent;
    transition: all 0.3s ease;
    box-shadow: 0 0 0px rgba(0, 0, 0, 0);
    color: $main-text-light-gray;
    text-wrap: wrap;
  }

  &:hover > div {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
  }
}

.repo-card { 
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  background-color: red;
  word-wrap: break-word;
  overflow-wrap: break-word;

  h3 {
    font-size: 1.25rem;
    color: $main-text-light;
  }

  .description {
    font-size: 1rem;
    font-family: sans-serif !important;
    color: $main-text-light-gray;
    font-weight: 300;
  }

  .repo-image {
    width: 30px;
    border-radius: 50%;
  }

  .language {
    padding-top: 5px;
    color: $secondary-highlight;
    display: inline-flex;
  }

  .dot {
    height: 15px;
    width: 15px;
    margin-right: 8px;
    border-radius: 50%;
    border: 1px solid $main-light;
    background-color: $purple;

    &.javascript {
      background-color: $green;
    }
    &.typescript {
      background-color: $blue;
    }
    &.none {
      background-color: $red;
    }
  }
}


footer {
  margin-top: auto;

  a {
    color: $main-light;
  }

  p {
    font-size: 12px;
    padding: 0;
    margin: 8px;
    color: $footer-text-light;
  }

  svg,
  svg>* {
    height: 30px;
    width: 30px;
    color: green !important;
    fill: green !important;
    fill-opacity: 0.5;
    stroke: green !important;
  }
}