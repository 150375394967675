$main-dark: #5c5c5c;
$main-light: #E9C46A;
$main-highlight: #ec5e30;
$secondary-highlight: #e07f2f;
$secondary-highlight-hover: #b96c2c;
$secondary-dark: #313131;

$main-text-light:  #f0f0f0;
$main-text-light-gray: #d7d8d8;
$footer-text-light: #afb6ba;

// Github repo colors
$red: #d73a49;
$purple: #6f42c1;
$green: #2ea44f;
$blue: #0366d6;